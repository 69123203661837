import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import account from "../../assets/images/account.svg";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { baseName } from "../../config/ApiConstants";
import { logout } from "../../Store/Auth/Action";
import { getCuurentUser, resetUser } from "../../Store/User/Action";
import CommonAuthModal from "../Modal/CommonAuthModal";
import ToastComponent from "../Toast";
import { resetDashboardData } from "../../Store/Dashboard/Action";
import { checkLoginPaths, PATHS } from "../../constants/Paths";
import { loadPlans } from "../../Store/Home/Action";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { chatIconRoutes, rediectToAppStore } from "../../constants/Utils";

function Header({ user }) {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
  const passwordChanged = localStorage.getItem("r_p_sucess");
  const [showNoti, setNotiShow] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [mobPlanstoggle, setMobPlansToggle] = useState(false);

  const [showCommon, setShowCommon] = useState(false);
  const [componentType, setComponentType] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const message = useSelector(({ alertMessage }) => alertMessage);
  const allPlans = useSelector((data) => data);
  const home = useSelector(({ home }) => home);

  const handleLoginShow = () => {
    setComponentType("login");
    setShowCommon(true);
  };

  const handleClose = () => setShowCommon(false);
  const { t, i18n } = useTranslation();
  const expression = "header";

  useEffect(() => {
    window.addEventListener("resize", updateView);
    return () => window.removeEventListener("resize", updateView);
  });

  useEffect(() => {
    dispatch(loadPlans());
  }, [i18n.language]);

  const updateView = () => {
    setDesktop(window.innerWidth > 768);
  };

  const handleLogout = () => {
    dispatch(logout(navigate));
    dispatch(getCuurentUser());
    dispatch(resetDashboardData());
    dispatch(resetUser());
  };

  useEffect(() => {
    if (passwordChanged) {
      handleLoginShow();
      localStorage.removeItem("r_p_sucess");
    }
  }, [passwordChanged]);

  // Chat icon visibility for certain paths
  useEffect(() => {
    if (chatIconRoutes.filter((path) => pathname === path).length) {
      document.body.classList.add("add-chat");
    } else document.body.classList.remove("add-chat");
  }, [pathname]);

  return (
    <>
      {message.toastMessage && (
        <ToastComponent message={message.toastMessage} bg={message.code} />
      )}

      {/* Banner for App store download link */}
      <div
          style={{
            backgroundColor: "black",
            color: "#fff",
            padding: "7px",
            alignItems: "center",
          }}
        >
          {t('header.bannerText')}
          <br />
          {t('header.click')}{" "}
          <a
            href="https://apps.apple.com/us/app/anona-diet/id1661103386"
            target="_blank"
            style={{ color: "#fff",textDecoration:"underline" }}
          >
            {" "}
            {t('header.here')}
          </a>
        {" "} {t('header.toDownload')}
      </div>

      <header>
        {isDesktop ? (
          <div className="header-desktop">
            <Link to={baseName} id="logo-wrap">
              <img className="header-logo" src={logo} alt="Lifestyle Logo" />
            </Link>
            <div id="right-nav">
              <div className="primary-nav">
                <Link to={baseName} className="nav-link home active">
                  {t(`${expression}.home`)}
                </Link>

                <div className="subscriptions-blk">
                  <a className="nav-link subscriptions">
                    {t(`${expression}.subscriptions`)}
                  </a>
                  <ul className="submenu" id="subscription-menu">
                    {home?.plans?.pakageList &&
                      home?.plans?.pakageList?.map((plan, ind) => (
                        <li key={ind}>
                          <Link
                            to="#"
                            onClick={() => rediectToAppStore()}
                            className={`submenu-link ${
                              ind === home?.plans?.pakageList.length - 1
                                ? "last-link"
                                : ""
                            }`}
                          >
                            {plan?.package_name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>

              <div className="account-nav">
                <a href="tel:+965-22070030" className="nav-link tel">
                  {t(`${expression}.mobile`)}
                </a>
                <LanguageSwitcher />
                {user == null ? (
                  <Button
                    variant="light"
                    className="btn-outline btn-login"
                    onClick={() => {
                      if (
                        window.location.href
                          .split("/")
                          ?.includes(checkLoginPaths.PLAN_DETAILS) ||
                        window.location.href
                          .split("/")
                          ?.includes(checkLoginPaths.RESULT)
                      ) {
                        localStorage.setItem("toD", true);
                      }
                      rediectToAppStore();
                     //handleLoginShow();
                    }}
                  >
                    {t(`${expression}.button`)}
                  </Button>
                ) : (
                  <div className="logged-in">
                    <div className="user-noti">
                      <div className="user-account">
                        <div className="user">
                          <img
                            className="user-img"
                            src={account}
                            alt="User Image"
                          />
                          <span className="user-name">{user?.first_name}</span>
                        </div>
                        <ul className="submenu" id="user-menu">
                          <li>
                            <Link
                              to={baseName + "dashboard"}
                              className="submenu-link dashboard"
                            >
                              {t(`${expression}.dashboard`)}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={baseName + "profile"}
                              className="submenu-link profile"
                            >
                              {t(`${expression}.profile`)}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={baseName}
                              className="submenu-link logout"
                              onClick={handleLogout}
                            >
                              {t(`${expression}.logout`)}
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="notification-blk">
                        <div
                          className="notification"
                          onClick={() => setNotiShow(!showNoti)}
                        >
                          <i className="icon-noti" />
                          <span className="icon-badge"></span>
                        </div>
                        <div
                          className={`notification-content ${
                            showNoti ? "" : ""
                          }`}
                        >
                          <div className="notification-header">
                            <h3 className="blk-title">
                              {t(`${expression}.notifications`)}
                            </h3>
                            <Button
                              variant="light"
                              className="notification-close"
                              onClick={() => setNotiShow(!showNoti)}
                            ></Button>
                          </div>
                          <Tabs
                            defaultActiveKey="delivery"
                            id="notification-tab"
                          >
                            <Tab eventKey="delivery" title="Delivery">
                              <ul className="list">
                                <li>
                                  <span className="day">
                                    {t(`${expression}.today`)}
                                  </span>
                                  <div className="desc-blk">
                                    <div className="top-blk">
                                      <h5 className="title">
                                        {t(`${expression}.lateDeliveryTitle`)}
                                      </h5>
                                      <span className="time">4:30 PM</span>
                                    </div>
                                    <p className="desc">
                                      {t(`${expression}.lateDeliveryDesc`)}
                                    </p>
                                  </div>
                                  <div className="desc-blk">
                                    <div className="top-blk">
                                      <h5 className="title">
                                        {t(`${expression}.skipMeal`)}
                                      </h5>
                                      <span className="time">4:30 PM</span>
                                    </div>
                                    <p className="desc">
                                      {t(`${expression}.skipMealText`)}
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <span className="day">
                                    {t(`${expression}.yesterday`)}
                                  </span>
                                  <div className="desc-blk">
                                    <div className="top-blk">
                                      <h5 className="title">
                                        {t(`${expression}.changeMeal`)}
                                      </h5>
                                      <span className="time">4:30 PM</span>
                                    </div>
                                    <p className="desc">
                                      {t(`${expression}.changeMealUpdate`)}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </Tab>
                            <Tab
                              eventKey="offers"
                              title={t(`${expression}.offers`)}
                            >
                              {t(`${expression}.offers`)}
                            </Tab>
                            <Tab
                              eventKey="subscription"
                              title={t(`${expression}.subscription`)}
                            >
                              {t(`${expression}.subscription`)}
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="mob-menu-wrap">
            <div>
              <Button
                variant="link"
                onClick={() => setToggle(!toggle)}
                className="btn-mob-toggle"
              ></Button>
              <Link to={baseName} id="logo-wrap">
                <img className="header-logo" src={logo} alt="Lifestyle Logo" />
              </Link>
            </div>
            <div id="right-nav">
              {toggle && (
                <div id="overlay">
                  <div className="toggle-content">
                    <Button
                      variant="link"
                      onClick={() => setToggle(!toggle)}
                      className="btn-toggle-close"
                    ></Button>
                    <div id="toggle-menu" className="primary-nav">
                      {user == null ? (
                        <Button
                          variant="light"
                          className="btn-outline btn-login btn-moblogin mb-3"
                          onClick={() => {
                            handleLoginShow();
                            setToggle(!toggle);
                          }}
                        >
                          {t(`${expression}.button`)}
                        </Button>
                      ) : (
                        <div className="user-account user-acc-mobwrap">
                          <Link to="#" className="user">
                            <img
                              className="user-img mob-userimg"
                              src={account}
                              alt="User Image"
                            />
                            <div className="mob-wrapp">
                              <label className="user-name mob-username">
                                {t(`${expression}.hello`)} {user?.first_name}
                              </label>
                              <span className="user-mail">{user?.email}</span>
                            </div>
                          </Link>
                        </div>
                      )}

                      {allPlans?.user?.currentUser && (
                        <div className="hme-dashbord">
                          <Link to={`${baseName}dashboard`}>
                            <Button
                              variant="light"
                              className="btn-fill btn-calculate hme-dashbord-btn-mob "
                              onClick={() => setToggle(!toggle)}
                            >
                              {t(`${expression}.dashboardButton`)}
                            </Button>
                          </Link>
                        </div>
                      )}
                      <Link
                        to={baseName}
                        className="nav-link home active mob-usr-home"
                        onClick={() => setToggle(!toggle)}
                      >
                        {t(`${expression}.home`)}
                      </Link>
                      <a
                        className={`nav-link subscriptions ${
                          mobPlanstoggle ? "-active" : ""
                        }`}
                        onClick={() => setMobPlansToggle(!mobPlanstoggle)}
                      >
                        {t(`${expression}.subscriptions`)}
                      </a>
                      <ul
                        className="submenu mob-list-usr"
                        id="subscription-menu "
                      >
                        {mobPlanstoggle && (
                          <>
                            {home?.plans?.pakageList?.map((plan, ind) => (
                              <li
                                key={`mob-${ind}`}
                                onClick={() => {
                                  navigate(
                                    PATHS.PLAN_DETAILS_ONLY +
                                      plan?.package_master_id
                                  );
                                  setToggle(!toggle);
                                }}
                                className={`submenu-link ${
                                  ind === home?.plans?.pakageList.length - 1
                                    ? "last-link"
                                    : ""
                                }`}
                              >
                                {plan?.package_name}
                              </li>
                            ))}
                          </>
                        )}
                      </ul>
                      <a
                        href="tel:+965-22070030"
                        className="nav-link tel mob-call-usr"
                      >
                        {t(`${expression}.mobile`)}
                      </a>

                      {(function () {
                        if (user != null) {
                          return (
                            <div className="mob-logout">
                              <Link
                                to={baseName}
                                className="submenu-link logout"
                                onClick={handleLogout}
                              >
                                {t(`${expression}.logout`)}
                              </Link>
                            </div>
                          );
                        }
                      })()}
                    </div>
                  </div>
                </div>
              )}
              <div className="account-nav">
                <LanguageSwitcher />
                {user == null ? (
                  <Button
                    variant="light"
                    className="btn-outline btn-login"
                    onClick={handleLoginShow}
                  >
                    {t(`${expression}.button`)}
                  </Button>
                ) : (
                  <div className="logged-in">
                    <div className="user-noti">
                      <div className="user-account">
                        <div className="user userheader">
                          <img
                            className="user-img"
                            src={account}
                            alt="User Image"
                          />
                          <span className="user-name">{user?.first_name}</span>
                        </div>
                        <ul className="submenu" id="user-menu">
                          <li>
                            <Link
                              to={baseName + "dashboard"}
                              className="submenu-link dashboard"
                            >
                              {t(`${expression}.dashboard`)}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={baseName + "profile"}
                              className="submenu-link profile"
                            >
                              {t(`${expression}.profile`)}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={baseName}
                              className="submenu-link logout"
                              onClick={handleLogout}
                            >
                              {t(`${expression}.logout`)}
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="notification-blk">
                        <div
                          className="notification"
                          onClick={() => setNotiShow(!showNoti)}
                        >
                          <i className="icon-noti" />
                          <span className="icon-badge"></span>
                        </div>
                        <div
                          className={`notification-content ${
                            showNoti ? "" : ""
                          }`}
                        >
                          <div className="notification-header">
                            <h3 className="blk-title">
                              {t(`${expression}.notifications`)}
                            </h3>
                            <Button
                              variant="light"
                              className="notification-close"
                              onClick={() => setNotiShow(!showNoti)}
                            ></Button>
                          </div>
                          <Tabs
                            defaultActiveKey="delivery"
                            id="notification-tab"
                          >
                            <Tab eventKey="delivery" title="Delivery">
                              <ul className="list">
                                <li>
                                  <span className="day">
                                    {t(`${expression}.today`)}
                                  </span>
                                  <div className="desc-blk">
                                    <div className="top-blk">
                                      <h5 className="title">
                                        {t(`${expression}.lateDeliveryTitle`)}
                                      </h5>
                                      <span className="time">4:30 PM</span>
                                    </div>
                                    <p className="desc">
                                      {t(`${expression}.lateDeliveryDesc`)}
                                    </p>
                                  </div>
                                  <div className="desc-blk">
                                    <div className="top-blk">
                                      <h5 className="title">
                                        {t(`${expression}.skipMeal`)}
                                      </h5>
                                      <span className="time">4:30 PM</span>
                                    </div>
                                    <p className="desc">
                                      {t(`${expression}.skipMealText`)}
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <span className="day">
                                    {t(`${expression}.yesterday`)}
                                  </span>
                                  <div className="desc-blk">
                                    <div className="top-blk">
                                      <h5 className="title">
                                        {t(`${expression}.changeMeal`)}
                                      </h5>
                                      <span className="time">4:30 PM</span>
                                    </div>
                                    <p className="desc">
                                      {t(`${expression}.changeMealUpdate`)}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </Tab>
                            <Tab
                              eventKey="offers"
                              title={t(`${expression}.offers`)}
                            >
                              {t(`${expression}.offers`)}
                            </Tab>
                            <Tab
                              eventKey="subscription"
                              title={t(`${expression}.subscription`)}
                            >
                              {t(`${expression}.subscription`)}
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </header>
      <CommonAuthModal
        show={showCommon}
        handleClose={handleClose}
        type={componentType}
        setComponentType={setComponentType}
      />
    </>
  );
}

export default Header;
