// PATHS
// export const baseName = "/lifestyle-revamp/";
// export const baseName = "/uat-revamp/"

export const baseName = "/"
// /lifestyle-revamp/

// export const BASE_URL = "https://anona-diet-dev.qburst.build/backend-new";

export const snapChatPixelId = "176f432b-ffcf-4bf2-b784-5c3abaa55b58";
export const metaPixelId = "652737392415307";

// export const BASE_URL = "https://anonadiet.com/admin/ws";
export const BASE_URL = "https://uat.anonadiet.com/admin/ws";

