import React from "react";
import "./App.scss";
import RoutesIndex from "./routes";
import "./config/Axios-middleware";
import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "./i18n/config";
import { HelmetProvider } from "react-helmet-async";
import Seo from "./constants/Seo";
import { metaDescriptions, metaTitles } from "./constants/Utils";
import { Freshchat } from "reactjs-freshchat";
import "reactjs-freshchat/dist/index.css";
import { useSelector } from "react-redux";

function App() {
  const helmetContext = {};
  const currentUser = useSelector(({ user }) => user);
  return (
    <>
      <div className="App">
        <HelmetProvider context={helmetContext}>
          <Seo
            title={metaTitles["anona"]}
            description={metaDescriptions["anona"]}
          />
          <RoutesIndex />
        </HelmetProvider>
      </div>
      {/* <Freshchat
        token="4534e18f-05cc-4c9a-9c08-aca5b74615c8"
        label="Support"
        firstName={currentUser?.currentUser?.first_name}
        lastName={currentUser?.currentUser?.last_name}
        email={currentUser?.currentUser?.email}
        ic_styles={{
          backgroundColor: "#002d85",
          color: "#fff",
          textAlign:'center',
          fontWeight:'bold'
        }}
      /> */}
    </>
  );
}

export default App;
